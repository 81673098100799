import {Typography} from '@mui/material';

type Props = {
    children : string | JSX.Element;
};

const TermsBold = ({children} : Props) : JSX.Element => {
    return (<Typography component="span" sx={{fontWeight: 'bold'}}>{children}</Typography>);
};

export default TermsBold;
