import {Card, Typography} from '@mui/material';
import TermsBold from '@/components/TermsBold';
import TermsEmphasis from '@/components/TermsEmphasis';

const Terms = () : JSX.Element => {
    return (<Card
        sx={{
            px: 3,
            borderRadius: 2,
            maxHeight: '70vh',
            maxWidth: '800px',
            overflow: 'hidden',
            overflowY: 'auto',
        }}
    >
        <p><TermsBold>TERMS & CONDITIONS:</TermsBold> By acceptance,
            the purchaser agrees to the following:</p>

        <p><TermsBold>Parties:</TermsBold> SBI Fine Fabric Finishing,
            will be referred to as "SBI" or "Seller" and the person or company purchasing will be referred to as
            "Purchaser". All materials, goods, services or work, regardless of type will be referred to as
            "Services".</p>

        <p><TermsBold>SBI does not inspect or measure goods prior to
            processing.</TermsBold> <TermsEmphasis>
            See more at Appendix part 1.</TermsEmphasis> SBI disclaims any warranties of merchantability, suitability
            for a particular purpose or consequential or incidental damages. SBI reserves the right to inspect and
            reprocess any goods deemed unacceptable by the customer.  It is the responsibility of the fabric supplier
            to provide their customer with first quality
            goods. <TermsEmphasis>See more at Appendix
            part 2.</TermsEmphasis> If you are unsure of the quality or condition of your goods, SBI offers Fabric
            Inspections as a separate service, for an additional charge.</p>

        <p><TermsBold>Returned Products/ Claims:</TermsBold> All work
            is custom ordered, as such, <TermsBold>there are no refunds,
            returns or exchanges allowed without SBI’s prior written consent.</TermsBold> It is the Customer's
            responsibility to determine by testing, samples or other means, if the fabric they have chosen if
            suitable for their intended purpose. Risk of loss or damage during shipment is upon the
            Purchaser. <TermsBold>Claims must be made in writing within
            30 days of receipt of fabric.</TermsBold> Claims shall be considered only for fabrics damaged by
            improper processing and shall be limited to the purchase price of the Services ordered from
            SBI. <TermsBold>No claim will be honored for fabric costs in
            excess of $10 per
            yard.</TermsBold> <TermsEmphasis>See more at Appendix part 3</TermsEmphasis></p>

        <p><TermsBold>Shrinkage/ Working Allowance:</TermsBold> Allow for
            5% or at least 1/2-yard additional fabric per piece, per treatment for testing, shrinkage and working
            allowance. For example, Stain Protection and Knit Backing are two separate treatments; in this case,
            order an extra 1 yard per piece (or 10%). </p>

        <p><TermsBold>Sample Treatment:</TermsBold> Custom sample treatment is available for an additional charge.
            Sample treatments can simulate but are not guaranteed to duplicate full scale processing.</p>

        <p><TermsBold>Certificates:</TermsBold> Flame Retardant Certificates sent with invoice for fabric processed
            with a flame retardant. We will be happy to provide you with treated samples of all finishes, upon
            request.</p>

        <p><TermsBold>Testing Services:</TermsBold> Testing services, can vary with each test, depending on
            environmental factors and testing method. SBI tests the fabric and ensures it passes our standards.
            SBI cannot guarantee that these results will be replicated in another test, as test results from different
            labs can vary widely.</p>

        <p><TermsBold>Changes/ Cancellation:</TermsBold> Orders placed and accepted by SBI (all orders are subject
            to acceptance by SBI) may not be changed or cancelled, except upon terms that will fully compensate SBI
            against loss. <TermsEmphasis>See more at Appendix part 4</TermsEmphasis></p>

        <p><TermsBold>Payment Terms:</TermsBold> All services must be paid in full prior to shipment, unless
            alternative payment terms are agreed to. Accounts past due may be placed on "stop shipment/credit hold"
            until all outstanding balances are paid, at which time the account may be placed on pro forma status
            until good credit can be reestablished.</p>

        <p><TermsBold>Unclaimed fabrics:</TermsBold> Fabrics held for more than 30 days are subject to storage charges.
            Fabrics held for more than six (6) months will be sold or discarded.</p>

        <p><TermsBold>Re-rolling Charges:</TermsBold> Fabrics must be provided to us ROLLED ON A TUBE. Fabrics sent to
            us folded, boxed, on boards or otherwise not rolled on a tube must be re-rolled. Charges for time and
            material apply.</p>

        <p><TermsBold>Cutting:</TermsBold> SBI is not equipped to cut material and material should be sized and
            ready for treatment, upon receipt. <TermsEmphasis>See more at Appendix part 5</TermsEmphasis></p>

        <p><TermsBold>Shipping Insurance & Charges:</TermsBold> Individual shipments are insured for a maximum of
            $ 500 per package unless additional insurance is requested on the order. - Package charge: $20 UPS & FedEx.
            Package charge when shipped in box: $50.  Additional charge for international & truck shipments.  Service
            cost for coordinating with logistics company: $35.</p>

        <p><TermsBold>Mandatory Arbitration & Venue:</TermsBold> It is hereby agreed that any and all disputes between
            SBI and Purchaser shall be governed by the laws of the State of Texas and subject to mandatory mediation
            and if agreement cannot be reached, subject to mandatory arbitration.</p>

        <p><TermsBold>Entire Agreement:</TermsBold> These Terms and Conditions are incorporated into the terms and
            conditions of the sale by reference. Purchaser and SBI acknowledge that the terms and conditions of sale,
            together with SBI’s invoice, constitute the entire agreement between Purchaser and SBI and supersede all
            prior oral or written statements of any kind made by the parties or their representatives. These terms
            and conditions of sale may not be amended, modified, or supplemented except by written agreement executed
            by the legal owners or representatives of Purchaser and SBI. The Terms and Conditions of sale are hereby
            deemed by the parties to be severable and the invalidity or unenforceability of one provision shall not
            affect the validity and enforceability of any other provision.</p>

        <Typography sx={{textAlign: 'center', fontSize: 'h3.fontSize'}}>Appendix</Typography>
        <ol>
            <li>Customer must mark or clearly identify the face of the fabric (or send a swatch with the face clearly
                marked) on all backing and lamination orders, before fabric can be processed, no exceptions. Backings
                are permanent and cannot be removed. Inspect goods carefully upon receipt. SBI does not accept
                responsibility for dye performance or fabric manufacturing defects in fabrics received. Returns for
                reprocessing or Claims cannot be considered after material has been cut or fabricated in any way</li>
            <li>All fabrics submitted to SBI for Services, must be first quality goods. It is the responsibility of
                the fabric supplier to provide the Purchaser with first quality goods, with an appropriate description
                of the material and suitability for treatment. If Purchaser does not have sufficient information, it
                is their responsibility to obtain the necessary information to ensure the fabric is compatible with
                the Services requested. The ultimate responsibility for determining if the fabric is suitable for
                treatment lies with the Customer. SBI can offer advice, based on our experience, but final
                responsibility is the customer’s. SBI offers sample testing, which can assist in this process, but
                is not a guarantee treatment on a larger swath of fabric will be successful.</li>
            <li>Failure to give timely notice, in writing, in accordance with this paragraph, shall bar any claim
                with regard to same and shall constitute irrevocable acceptance by Purchaser of the Services.
                Purchaser’s remedies are limited to those set forth herein,and shall not exceed the purchase price
                of the Services from SBI, upon which the liability is based. Any claim for loss or damage to Services
                during shipment shall be made by the Purchaser to the freight carrier, not SBI, even if SBI’s carrier
                account is utilized. SBI is not responsible for freight costs, shortages, and/ or late shipments of
                other suppliers. SBI is not responsible for the cost of the Purchaser's own material(s). It is
                understood that Purchaser will independently determine the suitability of processed material(s)
                for its purposes.</li>
            <li>Orders for non-standard or custom products to Purchaser’s specifications may be cancelled only prior
                to the commencement of processing and only to the extent that SBI is able to cancel orders. Purchaser
                shall be liable to SBI for all tooling and set-up costs associated with a change or cancellation.</li>
            <li>If SBI receives fabric that is too wide to process, Purchaser will be contacted and given the option
                for SBI to either: 1. Cut the fabric for an additional charge or 2. Return the fabric to purchaser
                to be cut. Should Purchaser request SBI cut the fabric, the fabric will be cut directly in half.
                No precision cutting is possible.</li>
        </ol>

    </Card>);
};

export default Terms;
