import {Typography} from '@mui/material';

type Props = {
    children : string | JSX.Element;
};

const TermsEmphasis = ({children} : Props) : JSX.Element => {
    return (<Typography component="span" sx={{color: 'error.main', fontStyle: 'italic'}}>{children}</Typography>);
};

export default TermsEmphasis;
